input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-text-fill-color: white !important;
    transition: background-color 5000s ease-in-out 0s;
    color: white !important;
}

.error {
    height: 15px;
    font-size: 0.7em;
    margin-left: 0.7em;
    color: rgb(212, 25, 25);
    padding: 0;
}

.errorContainer {
    height: 10px;
    margin-bottom: 0.2em;
    display: flex;
    flex-direction: column;
    width: 100%;
}


.input-file-field {
    width: 100%;
    position: relative;
    overflow: hidden;
}


.input-file-container {
    display: flex;
    align-items: center;
    width: 100%;
    position: absolute;
    margin-top: -47px;
    left: 15px;
    gap: 0.5em;
    padding: 0 1.5em 0 0;
}

input[type="file"] {
    z-index: 99;
    padding: 1em;
    visibility: hidden;
}

.input-file-label {
    white-space: nowrap;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 0.5em;
    border-radius: 5px;
    z-index: 99;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    color: #36a9df;
    font-weight: bold;
}

.input-file-label:hover {
    transform: translate(1px, 1px);
    background-color: white;
}

.css-14c0ixm-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
    color: white !important;
}

.css-hzsja1-MuiInputBase-input-MuiFilledInput-input{
    color:#36a9df;
}
  

.css-14c0ixm-MuiFormLabel-root-MuiInputLabel-root.Mui-error {

    color: #d32f2f !important;
    margin-top: -9px !important;
    height: 20px;
    font-size: 0.9em;
}