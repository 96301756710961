@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;600&display=swap');

h1,h2,h3,h4,h5,h6{
    font-family: 'Montserrat', sans-serif;
}

p{
    font-family: 'Montserrat', sans-serif;
}

body {
  padding: 0!important;
  overflow: auto!important;
}

body::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}

body::-webkit-scrollbar-track {
  background: transparent !important;
  border-radius: 0px 5px 5px 0px;
}

body::-webkit-scrollbar-thumb {
  background: rgba(138, 138, 138, 0.8);
  border-radius: 5px;
}

body::-webkit-scrollbar-thumb:hover {
  background: rgb(197, 197, 197);
  border-radius: 5px;
}

body::-webkit-scrollbar-button {
  display: none;
}


header {
  padding: 0!important;
}

.App {
  text-align: center;
  background-color: #36a9df;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #36a9df;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 