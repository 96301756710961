.link { 
    text-decoration: none;
}


.MuiButtonBase-root-MuiButton-root {
    color: #36a9df !important;
}

.MuiButtonBase-root-MuiButton-root:hover {
    color: #01404F !important;
}

/* .MuiContainer-root{
    padding-left: 12 !important;
    margin-left: 0 !important;
} */