.requisitos-container {
    margin-bottom: 1em;
}

.requisitos-content {
    padding-left: 1em;
}

.accordion-content::-webkit-scrollbar {
    width: 10px;
}
  
.accordion-content::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0px 5px 5px 0px;
}
  
.accordion-content::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 5px;
}
  
.accordion-content::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
}

.accordion-content::-webkit-scrollbar-button {
    display: none;
}

.MuiAccordion-region::-webkit-scrollbar {
    width: 10px;
    background: transparent;
  }
  
  .MuiAccordion-region::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 0px 5px 5px 0px;
  }
  
  .MuiAccordion-region::-webkit-scrollbar-thumb {
    background: rgba(138, 138, 138, 0.8);
    border-radius: 5px;
  }
  
  .MuiAccordion-region::-webkit-scrollbar-thumb:hover {
    background: rgb(197, 197, 197);
    border-radius: 5px;
  }
  
  .MuiAccordion-region::-webkit-scrollbar-button {
    display: none;
  }


  .MuiAccordion-region {
    scrollbar-color: rgba(255, 255, 255, 0.671) rgba(0, 0, 0, 0);
    scrollbar-arrow-color: transparent; 
    scroll-behavior: smooth;
  }