.busquedas-container {
    width: 40%;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
}

@media(max-width: 900px) {
    .busquedas-container {
        width: 80%;
    }
}