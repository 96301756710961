.button-send {
    display: flex;
    text-transform: uppercase;
    font-family: 'Montserrat';
    font-weight: bold;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    padding: 0.5em;
    border-radius: 5px;
    color: #36a9df;
    transition: 0.3s all ease-in-out;
}

.button-send:hover {
    background-color: rgba(255, 255, 255);
}