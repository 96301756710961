a:hover {
    color: '#36a9df' !important
}

a:active {
    color: 'white'
}


a>.MuiButtonBase-root.MuiIconButton-root {
    color: #ffffff;
}

.MuiBox-root:focus{
    outline: none;
    
}